import 'bootstrap';

// or get all of the named exports for further usage
import * as bootstrap from 'bootstrap';

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// jQuery(function () {
//     jQuery(window).on('scroll', function(){
//         var sticky = jQuery('header'),
//             scroll = jQuery(window).scrollTop();
        
//         if (scroll >= 100) sticky.addClass('sticky');
//         else sticky.removeClass('sticky');
//     });

//     jQuery('.toggle-main-nav').on('click', function(e){
//         e.preventDefault();
//         jQuery('nav#main-nav').toggleClass('show');
//     });

// })
